<template>
  <v-app>
    <!-- Barra de Navegación Superior -->

    <!-- Menú Lateral Izquierdo -->
    <v-navigation-drawer v-model="drawer">
      <v-card>
        <v-layout>
          <v-navigation-drawer v-model="drawer" permanent @click="true">
            <v-list-item
              prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
              title="John Leider"
              nav
            >
            </v-list-item>
            <v-divider></v-divider>

            <v-list v-model:opened="open">
              <v-list-item
                prepend-icon="mdi-view-dashboard"
                title="Dashboard"
              ></v-list-item>
              <v-list-group value="Users">
                <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    prepend-icon="mdi-city"
                    title="Organization"
                  ></v-list-item>
                </template>
                <v-list>
                  <v-list-subheader>SITES</v-list-subheader>
                  <v-list-item
                    class="py-0 my-0"
                    v-for="(item, i) in itemSites"
                    :key="i"
                    :value="item"
                    color="primary"
                    variant="plain"
                  >
                    <template v-slot:append>
                      <router-link :to="item.create">
                        <v-btn density="compact" icon="mdi-plus"></v-btn
                      ></router-link>
                    </template>
                    <v-list-item-content>
                      <router-link :to="item.to">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </router-link>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-subheader>RACKS</v-list-subheader>
                  <v-list-item
                    v-for="(item, i) in imtemsRacks"
                    :key="i"
                    :value="item"
                    color="primary"
                    variant="plain"
                  >
                    <template v-slot:append>
                      <router-link :to="item.create">
                        <v-btn density="compact" icon="mdi-plus"></v-btn>
                      </router-link>
                    </template>
                    <v-list-item-content>
                      <router-link :to="item.to">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </router-link>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list>
                  <v-list-subheader>TENANCY</v-list-subheader>
                </v-list>
                <v-list>
                  <v-list-subheader>CONTACTS</v-list-subheader>
                </v-list>
              </v-list-group>
              <v-list-group value="Devices">
                <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    prepend-icon="mdi mdi-devices"
                    title="Devices"
                  ></v-list-item>
                </template>
                <v-list>
                  <v-list-subheader>DEVICES</v-list-subheader>
                  <v-list-item
                    class="py-0 my-0"
                    v-for="(item, i) in itemDevices"
                    :key="i"
                    :value="item"
                    color="primary"
                    variant="plain"
                  >
                    <template v-slot:append>
                      <router-link :to="item.create">
                        <v-btn density="compact" icon="mdi-plus"></v-btn
                      ></router-link>
                    </template>
                    <v-list-item-content>
                      <router-link :to="item.to">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </router-link>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-group>
              <v-list-group value="Admin">
                <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    prepend-icon="mdi mdi-account-supervisor"
                    title="Admin"
                  ></v-list-item>
                </template>
                <v-list>
                  <v-list-subheader>AUTHENTICATION</v-list-subheader>
                  <v-list-item
                    class="py-0 my-0"
                    v-for="(item, i) in itemAdmin"
                    :key="i"
                    :value="item"
                    color="primary"
                    variant="plain"
                  >
                    <template v-slot:append>
                      <router-link :to="item.create">
                        <v-btn density="compact" icon="mdi-plus"></v-btn
                      ></router-link>
                    </template>
                    <v-list-item-content>
                      <router-link :to="item.to">
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                      </router-link>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-group>
            </v-list>
          </v-navigation-drawer>
          <v-main style="height: 900px"></v-main>
        </v-layout>
      </v-card>
    </v-navigation-drawer>
    <v-app-bar color="blue-lighten-1" app>
      <v-text-field
        class="mx-5 mt-4"
        label="Search"
        append-inner-icon="mdi mdi-magnify"
        variant="outlined"
        density="compact"
      ></v-text-field>
    </v-app-bar>
    <p>asdasdad</p>
    <v-main class="mt-5 mx-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { Ref, ref } from "vue";
import { IMenuOption } from "./interface/IMenuOption";
let drawer = ref(true);

const open = ref(["Users"]);

const itemSites: Ref<Array<IMenuOption>> = ref([
  {
    text: "Sites",
    icon: "mdi-plus",
    to: { name: "site" },
    create: { name: "site-create" },
  },
  {
    text: "Regions",
    icon: "mdi-plus",
    to: { name: "region" },
    create: { name: "region-create" },
  },
  {
    text: "Site Groups",
    icon: "mdi-plus",
    to: { name: "site-group" },
    create: { name: "site-group-create" },
  },
  {
    text: "Locations",
    icon: "mdi-plus",
    to: { name: "location" },
    create: { name: "location-create" },
  },
]);

const imtemsRacks: Ref<Array<IMenuOption>> = ref([
  {
    text: "Racks",
    icon: "mdi-plus",
    to: { name: "rack" },
    create: { name: "rack-create" },
  },
  {
    text: "Rack Roles",
    icon: "mdi-plus",
    to: { name: "rack-role" },
    create: { name: "rack-role-create" },
  },
  {
    text: "Reservations",
    icon: "mdi-plus",
    to: { name: "rack-reservation" },
    create: { name: "rack-reservation-create" },
  },
  {
    text: "Elevations",
    icon: "mdi-plus",
    to: { name: "location" },
    create: { name: "location-create" },
  },
]);
const itemAdmin: Ref<Array<IMenuOption>> = ref([
  {
    text: "Entity",
    icon: "mdi-plus",
    to: { name: "entity" },
    create: { name: "entity-create" },
  },
  {
    text: "Users",
    icon: "mdi-plus",
    to: { name: "user" },
    create: { name: "user-create" },
  },
]);
const itemDevices: Ref<Array<IMenuOption>> = ref([
  {
    text: "Device Roles",
    icon: "mdi-plus",
    to: { name: "device-role" },
    create: { name: "device-role-create" },
  },
  {
    text: "Devices Types",
    icon: "mdi-plus",
    to: { name: "device-type" },
    create: { name: "device-type-create" },
  },
  {
    text: "Platforms",
    icon: "mdi-plus",
    to: { name: "platform" },
    create: { name: "platform-create" },
  },
  {
    text: "Manufacturer",
    icon: "mdi-plus",
    to: { name: "manufacturer" },
    create: { name: "manufacturer-create" },
  },
]);
</script>
