import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import "@mdi/font/css/materialdesignicons.min.css";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as FilePond from "filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
FilePond.registerPlugin(FilePondPluginImagePreview);

axios.defaults.baseURL = "http://127.0.0.1:8000/api/";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
const vuetify = createVuetify({
  components,
  directives,
});
const app = createApp(App);
app.use(router);
app.use(vuetify);
app.use(VueAxios, axios);

app.mount("#app");
