import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/auth/AuthUserView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/AuthUserView.vue"),
  },
  /*START MODULE DCIM*/
  {
    path: "/site",
    name: "site",
    component: () => import("@/views/dcim/site/ListSiteView.vue"),
  },
  {
    path: "/site/:site",
    name: "site-show",
    component: () => import("@/views/dcim/site/ShowSiteView.vue"),
  },
  {
    path: "/site/create",
    name: "site-create",
    component: () => import("@/views/dcim/site/CreateSiteView.vue"),
  },
  {
    path: "/region",
    name: "region",
    component: () => import("@/views/dcim/region/ListRegionView.vue"),
  },
  {
    path: "/region/:region",
    name: "region-show",
    component: () => import("@/views/dcim/region/ShowRegionView.vue"),
  },
  {
    path: "/region/create",
    name: "region-create",
    component: () => import("@/views/dcim/region/CreateRegionView.vue"),
  },
  {
    path: "/site-group",
    name: "site-group",
    component: () => import("@/views/dcim/site_group/ListSiteGroupView.vue"),
  },
  {
    path: "/site-group/:siteGroup",
    name: "site-group-show",
    component: () => import("@/views/dcim/site_group/ShowSiteGroupView.vue"),
  },
  {
    path: "/site-group/create",
    name: "site-group-create",
    component: () => import("@/views/dcim/site_group/CreateSiteGroupView.vue"),
  },
  {
    path: "/location",
    name: "location",
    component: () => import("@/views/dcim/location/ListLocationView.vue"),
  },
  {
    path: "/location/:location",
    name: "location-show",
    component: () => import("@/views/dcim/location/ShowLocationView.vue"),
  },
  {
    path: "/location/create",
    name: "location-create",
    component: () => import("@/views/dcim/location/CreateLocationView.vue"),
  },
  {
    path: "/rack-role",
    name: "rack-role",
    component: () => import("@/views/dcim/rack-role/ListRackRoleView.vue"),
  },
  {
    path: "/rack-role/:rackRole",
    name: "rack-role-show",
    component: () => import("@/views/dcim/rack-role/ShowRackRoleView.vue"),
  },
  {
    path: "/rack-role/create",
    name: "rack-role-create",
    component: () => import("@/views/dcim/rack-role/CreateRackRoleView.vue"),
  },
  {
    path: "/rack",
    name: "rack",
    component: () => import("@/views/dcim/rack/ListRackView.vue"),
  },
  {
    path: "/rack/:rack",
    name: "rack-show",
    component: () => import("@/views/dcim/rack/ShowRackView.vue"),
  },
  {
    path: "/rack/create",
    name: "rack-create",
    component: () => import("@/views/dcim/rack/CreateRackView.vue"),
  },
  {
    path: "/rack-reservation",
    name: "rack-reservation",
    component: () =>
      import("@/views/dcim/rackReservation/ListRackReservationView.vue"),
  },
  {
    path: "/rack-reservation/:rackReservation",
    name: "rack-reservation-show",
    component: () =>
      import("@/views/dcim/rackReservation/ShowRackReservationView.vue"),
  },
  {
    path: "/rack-reservation/create",
    name: "rack-reservation-create",
    component: () =>
      import("@/views/dcim/rackReservation/CreateRackReservationView.vue"),
  },
  {
    path: "/device-role",
    name: "device-role",
    component: () => import("@/views/dcim/deviceRole/ListDeviceRoleView.vue"),
  },
  {
    path: "/device-role/:deviceRole",
    name: "device-role-show",
    component: () => import("@/views/dcim/deviceRole/ShowDeviceRoleView.vue"),
  },
  {
    path: "/device-role/create",
    name: "device-role-create",
    component: () => import("@/views/dcim/deviceRole/CreateDeviceRoleView.vue"),
  },
  {
    path: "/manufacturer",
    name: "manufacturer",
    component: () =>
      import("@/views/dcim/manufacturer/ListManufacturerView.vue"),
  },
  {
    path: "/manufacturer-show/:manufacturer",
    name: "manufacturer-show",
    component: () =>
      import("@/views/dcim/manufacturer/ShowManufacturerView.vue"),
  },
  {
    path: "/manufacturer/create",
    name: "manufacturer-create",
    component: () =>
      import("@/views/dcim/manufacturer/CreateManufacturerView.vue"),
  },
  {
    path: "/platform",
    name: "platform",
    component: () => import("@/views/dcim/platform/ListPlatformView.vue"),
  },
  {
    path: "/platform-show/:platform",
    name: "platform-show",
    component: () => import("@/views/dcim/platform/ShowPlatformView.vue"),
  },
  {
    path: "/platform/create",
    name: "platform-create",
    component: () => import("@/views/dcim/platform/CreatePlatformView.vue"),
  },
  {
    path: "/device-type",
    name: "device-type",
    component: () => import("@/views/dcim/deviceType/ListDeviceTypeView.vue"),
  },
  {
    path: "/device-type/:deviceType",
    name: "device-type-show",
    component: () => import("@/views/dcim/deviceType/ShowDeviceTypeView.vue"),
  },
  {
    path: "/device-type/create",
    name: "device-type-create",
    component: () => import("@/views/dcim/deviceType/CreateDeviceTypeView.vue"),
  },

  /*END MODULE DCIM*/

  /*START MODULE ADMIN*/
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/gateway/user/ListUserView.vue"),
  },
  {
    path: "/user-show/:user",
    name: "user-show",
    component: () => import("@/views/gateway/user/ShowUserView.vue"),
  },
  {
    path: "/user/create",
    name: "user-create",
    component: () => import("@/views/gateway/user/CreateUserView.vue"),
  },
  {
    path: "/entity",
    name: "entity",
    component: () => import("@/views/gateway/entity/ListEntityView.vue"),
  },
  {
    path: "/entity-show/:entity",
    name: "entity-show",
    component: () => import("@/views/gateway/entity/ShowEntityView.vue"),
  },
  {
    path: "/entity/create",
    name: "entity-create",
    component: () => import("@/views/gateway/entity/CreateEntityView.vue"),
  },
  /*END MODULE ADMIN */
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
